<template>
  <div :class="{ 'is-intro': intro }" class="chat">
    <div class="bot">
      <div v-for="(m, i) in bot" :key="i" class="message" v-html="m" />
    </div>

    <div :class="[ cursor.bg, { active: cursor.active }]" class="cursor" />

    <div
      :contenteditable="!disabled"
      class="human"
      v-on:keyup.enter="send()"
      @input="inputText"
      ref="input"
      :disabled="disabled"
    >{{ human }}</div>

    <div class="shadow" />
  </div>
</template>

<script>
const messages = [
  'Let the products identity be developed with the product itself by people without professional skills. For example, it could be an agency\'s manager, an accountant, or a lawyer.',
  'AvatarX - This name implies that the service can provide users with a professional avatar to help them achieve their creative goals.',
  'Innovateo - This name combines "innovate" with the suffix "-eo," which is often used to create neologisms.',
  'Switchcraft - a versatile solution that effortlessly transforms your creative ideas into reality, like magic.',
  'Imagin8 - A title that combines "imagine" and the number 8, suggesting infinite possibilities for creativity and innovation.',
  'ActAsPro - Allows users to act like a professional in any creative industry',
]

export default {
  name: 'Chat',
  data() {
    return {
      cursor: {
        bg: 'rainbow',
        active: false,
      },
      intro: true,
      input: '',
      bot: [
        'act as',
      ],
      human: 'Designer Copywriter Strategist',
      typingTimeout: null,
      disabled: true,
    }
  },
  mounted() {
    setTimeout(() => {
      this.bot.splice(0, 1)
      this.human = ''
      this.intro = false
      this.cursor.active = true

      this.botMessage(messages[0], 1500).then(() => {
        return this.botMessage(messages[1], 1000)
      }).then(() => {
        return this.botMessage(messages[2], 1000)
      }).then(() => {
        this.humanReady()
      })
    }, 3000)
  },
  methods: {
    botMessage(message, delay = 0) {
      return new Promise((resolve) => {
        setTimeout(() => {
          const m = message.split(' ')
          this.bot.push('')

          m.forEach((word, i) => {
            setTimeout(() => {
              this.bot[this.bot.length - 1] = `${this.bot[this.bot.length - 1]} ${word}`

              if (i === m.length - 1) {
                resolve()
              }
            }, i * 40)
          })
        }, delay)
      })
    },
    humanReady() {
      this.cursor.bg = 'white'
      this.cursor.active = true
      this.disabled = false
      this.$nextTick(() => {
        this.$refs.input.focus()
      })
    },
    send() {
      this.human = this.$refs.input.innerText
      this.disabled = true
      const message = this.human.split('')
      this.bot.push('')
      this.cursor.bg = 'rainbow'
      this.cursor.active = false

      message.forEach((letter, i) => {
        setTimeout(() => {
          this.bot[this.bot.length - 1] = this.bot[this.bot.length - 1] + letter
          this.human = this.human.slice(1)

          if (i === message.length - 1) {
            this.botMessage(messages[3], 2000).then(() => {
              this.humanReady()
            })
          }
        }, i * 20)
      })
    },
    inputText(e) {
      this.cursor.active = false
      clearTimeout(this.typingTimeout)
      this.typingTimeout = setTimeout(() => {
        this.cursor.active = true
      }, 500)
    },
  },
}
</script>

<style src="./chat.scss" lang="scss" scoped></style>
